import React, { Fragment } from 'react';
import Container from 'common/source/components/UI/Container';
import Image from 'reusecore/src/elements/Image';
import ResourceWrapper from './resource.style';

const ResourceView = ({ post }) => {
  const {
    html,
    frontmatter: { slug, title, cover, tags }
  } = post;
  return (
    <Container>
      <ResourceWrapper>
        <div className="post-tags">
          {tags.map((tag, index) => {
            const isLast = index === tags.length - 1;
            return (
              <Fragment key={`${slug}-${tag}`}>
                <span>{tag}</span>
                {!isLast && <span className="divider">|</span>}
              </Fragment>
            );
          })}
        </div>
        <div className="post-info">
          <h1>{title}</h1>
        </div>
        <div className="post-cover">
          <Image src={cover} alt={`UpSolar ${title}`} />
        </div>
        <div className="post-body" dangerouslySetInnerHTML={{ __html: html }} />
      </ResourceWrapper>
    </Container>
  );
};

export default ResourceView;
