import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
import ResourceView from 'common/source/containers/ResourceView';
import FooterRelatedList from 'common/source/containers/FooterRelatedList';

import Footer from 'common/source/containers/Footer';

import SEO from '../components/SEO';

class ResourceTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug, prevPost, nextPost } = pageContext;
    const { post } = data;

    const { frontmatter } = post;
    if (!frontmatter.id) {
      frontmatter.id = slug;
    }

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={post} postSEO prefix="recursos" />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar background="blue" />
            <ResourceView post={post} />
            <FooterRelatedList
              prevPost={prevPost}
              nextPost={nextPost}
              prefix="recursos"
              heading="Más Recursos"
              subtitle="Sigue leyendo otros recursos relacionados:"
            />
            <br />
            <br />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default ResourceTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ResourceBySlug($slug: String!) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug }, type: { eq: "RESOURCE" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
      }
      frontmatter {
        slug
        title
        type
        featured
        cover
        thumbnail
        date
        tags
      }
    }
  }
`;
