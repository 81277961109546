import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Container from '../../components/UI/Container';
import RelatedListWrapper, { GridContainer, RelatedItemWrapper } from './related-list.style';

const FooterRelatedList = ({ prevPost, nextPost, heading, subtitle, prefix }) => {
  return (
    <RelatedListWrapper>
      <Container>
        <GridContainer template="70% auto">
          <div className="inner-heading">
            {heading && <h2>{heading}</h2>}
            {subtitle && <p>{subtitle}</p>}
          </div>
          {prefix && (
            <div className="inner-nav">
              <Link to={`/${prefix}`}>
                Ver más
                {' '}
                <i className="flaticon-next" />
              </Link>
            </div>
          )}
        </GridContainer>
        <GridContainer template="1fr 1fr">
          <RelatedItemWrapper>
            <div className="resource-labels">
              {prevPost.frontmatter.tags.map((tag, index) => {
                const isLast = index === prevPost.frontmatter.tags.length - 1;
                return (
                  <Fragment key={`${prevPost.frontmatter.slug}-${tag}`}>
                    <span>{tag}</span>
                    {!isLast && <span className="divider">|</span>}
                  </Fragment>
                );
              })}
            </div>
            <div className="resource-info">
              <Link to={`${prefix ? `/${prefix}/` : '/'}${prevPost.frontmatter.slug}`}>
                <h3>{prevPost.frontmatter.title}</h3>
              </Link>
              <p>{prevPost.excerpt}</p>
            </div>
          </RelatedItemWrapper>
          <RelatedItemWrapper>
            <div className="resource-labels">
              {nextPost.frontmatter.tags.map((tag, index) => {
                const isLast = index === nextPost.frontmatter.tags.length - 1;
                return (
                  <Fragment key={`${nextPost.frontmatter.slug}-${tag}`}>
                    <span>{tag}</span>
                    {!isLast && <span className="divider">|</span>}
                  </Fragment>
                );
              })}
            </div>
            <div className="resource-info">
              <Link to={`${prefix ? `/${prefix}/` : '/'}${nextPost.frontmatter.slug}`}>
                <h3>{nextPost.frontmatter.title}</h3>
              </Link>
              <p>{nextPost.excerpt}</p>
            </div>
          </RelatedItemWrapper>
        </GridContainer>
        <GridContainer />
      </Container>
    </RelatedListWrapper>
  );
};

export default FooterRelatedList;
